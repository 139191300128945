import React from "react";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";


export const ReCaptcha = ({onChange, onRender, hidden}) => {
    useEffect(() => {
        onRender();
  });

  return (
        <ReCAPTCHA
          sitekey={process.env.GATSBY_CAPTCHA_KEY}
          onChange={onChange}
          hidden={hidden}
          theme='dark'
        />
  )
}
