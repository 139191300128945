exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-blog-tsx": () => import("./../../../src/pages/blog/blog.tsx" /* webpackChunkName: "component---src-pages-blog-blog-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-slug-tsx": () => import("./../../../src/pages/blog/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-mdx-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-curriculo-tsx": () => import("./../../../src/pages/curriculo.tsx" /* webpackChunkName: "component---src-pages-curriculo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

