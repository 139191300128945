import { styled } from "../../../stitches.config"

export const Quote = styled("blockquote", {
  length: 0,
  backgroundColor: "$slate4",
  borderRadius: "10px",
  color: "$slate11",
  fontSize: "$lg",
  lineHeight: "$400",
  marginBottom: "$8",
  marginTop: "$8",
  padding: "$5",
  p: {
    margin: 0,
    color: "$slate11",
    fontSize: "$lg",
    lineHeight: "$400",
  },
})
