module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-48GD9YC7LS"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto+Sans+Display:400,500,600","Noto+Sans:400"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/codebuild/output/src3289509514/src/node_modules/gatsby-plugin-twitter","id":"5831bbd8-9e85-53a6-b3be-7b3e9e557884","name":"gatsby-plugin-twitter","version":"4.24.0","modulePath":"/codebuild/output/src3289509514/src/node_modules/gatsby-plugin-twitter/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src3289509514/src/node_modules/gatsby-remark-prismjs","id":"7e64d001-bc40-54e6-89bf-fcf798456ac0","name":"gatsby-remark-prismjs","version":"6.24.0","modulePath":"/codebuild/output/src3289509514/src/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"aliases":{},"showLineNumbers":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/codebuild/output/src3289509514/src/node_modules/gatsby-remark-images","id":"4a359a36-fef3-5c49-a0f9-45c24a5fd434","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/codebuild/output/src3289509514/src/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":590},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src3289509514/src","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/content/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"66ceac1f6a6ccdcd643007cbca14652e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
