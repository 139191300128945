import React from 'react';
import { Linkedin,  GitHub, Gitlab } from 'react-feather';

export const ShareButtons = () => (
  <div className="post-meta-share-icons" >
    <ul>
      <li style={{display: "inline-block", paddingRight : '10px'}}>
        <a href="https://www.linkedin.com/in/matheus-tura/" target="_blank">
          <Linkedin strokeWidth={1.3} size={35}/>
        </a>
      </li>
      <li style={{display: "inline-block", paddingRight : '10px'}}>
        <a href="https://github.com/zatura" target="_blank">
          <GitHub strokeWidth={1.3} size={35}/>
        </a>
      </li>
      <li style={{display: "inline-block", paddingRight : '10px'}}>    
        <a href="https://gitlab.com/matheus-tura" target="_blank"> 
          <Gitlab strokeWidth={1.3} size={35} /> 
        </a>
      </li>
    </ul>
  </div>
);
