import React from "react";
import { useState } from "react";
import { Buffer } from 'buffer';
import { UnorderedList } from "..";
import { ReCaptcha } from "../ContactBox/ReCaptcha";



export const ContactBox = ({}) => {
  const email: string = "YldGMGFHVjFjeTUwZFhKaFFIQnliM1J2Ym0xaGFXd3VZMjl0";
  const matrix: string = "UUcxaGRHaGxkWE11ZEhWeVlUcHRZWFJ5YVhndWIzSm4=";
  const linkedin: string = "YkdsdWEyVmthVzR1WTI5dEwybHVMMjFoZEdobGRYTXRkSFZ5WVM4Swo=";
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [decodedEmail, setDecodedEmail] = useState("");
  const [decodedMatrix, setDecodedMatrix] = useState("");
  const [decodedLinkedin, setDecodedLinkedin] = useState("");

  const decode = (code: string) => {
    const decoded = Buffer.from(code, 'base64').toString('ascii');
    return Buffer.from(decoded, 'base64').toString('ascii');
  };

  const onChange = () => {
    setDecodedEmail(decode(email));
    setDecodedMatrix(decode(matrix));
    setDecodedLinkedin(decode(linkedin));
    setIsVerified(true);
  };

  const onRender = async () => {
    await sleep(3000);
    setIsLoading(false)
  };

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  return (
      <UnorderedList>
        <div hidden={!isLoading}>
          <div className="lds-ring">
            <div></div>
            <div></div>
          </div>
        </div>
        <ReCaptcha
          onChange={onChange} 
          onRender={onRender}
          hidden={isVerified || isLoading}
        />
        <div hidden={!isVerified}>
          {decodedEmail} <br/>
          {decodedMatrix} <br/>
          {decodedLinkedin}
        </div>
    </UnorderedList>
  )
}
