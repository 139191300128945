export const colors = {
  slate1: {
    color: "$slate1",
  },
  slate2: {
    color: "$slate2",
  },
  slate3: {
    color: "$slate3",
  },
  slate4: {
    color: "$slate4",
  },
  slate5: {
    color: "$slate5",
  },
  slate6: {
    color: "$slate6",
  },
  slate7: {
    color: "$slate7",
  },
  slate8: {
    color: "$slate8",
  },
  slate9: {
    color: "$slate9",
  },
  slate10: {
    color: "$slate10",
  },
  slate11: {
    color: "$slate11",
  },
  slate12: {
    color: "$slate12",
  },
}
